import { t } from 'shared/i18n';
// eslint-disable-next-line import/no-unresolved
import '../../../styles-compressed/legacy/products-collection-list-v2.css';
/**
 * Module that caters to the Javascript needs of the Products Collection v2.0
 * This is for the *visitor-facing* product list page (products-2.0.list).
 *
 * @module  squarespace-products-collection-list-v2
 */


YUI.add('squarespace-products-collection-list-v2', function (Y) {

  /**
   * @class       ListV2
   * @extends     Base
   * @namespace   Squarespace.SystemCollections.Products
   * @constructor
   */
  Y.namespace('Squarespace.SystemCollections.Products').ListV2 =
  Y.Base.create('systemCollectionsProductsListV2', Y.Base, [], {

    initializer: function () {

      this._loadImages();
      this._bindUI();
      this._syncUI();

    },

    destructor: function () {

      if (this._resizeEvent) {
        this._resizeEvent.detach();
      }
      if (this._tweakChangeEvent) {
        this._tweakChangeEvent.detach();
      }
      if (this._tweakResetEvent) {
        this._tweakResetEvent.detach();
      }

      Y.Array.each(this._onImageLoadEvents, function (onImageLoadEvent) {
        onImageLoadEvent.detach();
      });

      this._resizeEvent = null;
      this._tweakChangeEvent = null;
      this._tweakResetEvent = null;
      this._onImageLoadEvents = null;

    },

    _loadImages: function () {

      this._onImageLoadEvents = [];

      this.get('images').each(function (image) {

        image.plug(Y.Squarespace.Loader2, {
          load: true,
          mode: 'fill' });


        this._onImageLoadEvents.push(image.on('load', function () {
          if (image.hasClass('ProductList-image--primary')) {
            image.ancestor('.ProductList-item').addClass('image-is-loaded');
          }
        }));

      }, this);

    },

    _bindUI: function () {

      this._resizeEvent = Y.on('resize', function () {
        this._syncUI();
      }, Y.config.win, this);

      this._tweakChangeEvent = Y.Global.on('tweak:change', this._tweakChangeHandler, this);

      this._tweakResetEvent = Y.Global.on('tweak:reset', this._syncUI, this);

      if (window.SQUARESPACE_LOGIN && window.SQUARESPACE_LOGIN.isLoggedIn()) {
        Y.on('beforeunload', function () {
          this.destroy(true);
        }, this);
      }

    },

    _syncUI: function () {

      this._adjustSubPixelValues();

      this.get('images').each(function (image) {
        image.fire('refresh');
      });

    },

    /**
     * The grid is laid out in CSS using percentages. Avert a great many sub-pixel
     * rounding issues by immediately setting the grid items to a whole pixel width.
     *
     * @method _adjustSubPixelValues
     */
    _adjustSubPixelValues: function () {

      var items = this.get('host').all('.ProductList-item');

      if (items) {
        items.setStyle('width', '');
        items.setStyle('width', Math.floor(parseInt(items.getStyle('width')[0], 10)) + 'px');
      }

    },

    _tweakChangeHandler: function (field) {

      var tweak = field.getName();
      var value = field.getValue();

      if (tweak === 'tweak-product-list-image-aspect-ratio' ||
      tweak === 'tweak-product-list-item-spacing' ||
      tweak === 'tweak-product-list-filter-display' ||
      tweak === 'tweak-product-list-filter-width') {
        this._syncUI();
      } else if (tweak === 'tweak-product-list-items-per-row') {
        this._previewTweakChangeItemsPerRow();
        this._syncUI();
      } else if (tweak === 'tweak-product-list-item-hover-behavior' && value === t("Show Alternate Image"))

      {
        this._loadImages();
        this._previewTweakChangeItemHoverBehavior();
      }

    },

    /**
     * In the LESS, "items per row" is an interpolated variable contained in an
     * escaped string. Users won't see changes while the Style Editor is open
     * unless we forcibly update it as its value changes.
     * This is just for previewing purposes.
     *
     * @method _previewTweakChangeItemsPerRow
     */
    _previewTweakChangeItemsPerRow: function () {

      var itemsPerRow = parseInt(Y.Squarespace.Template.getTweakValue('tweak-product-list-items-per-row'), 10);

      this.get('host').all('.ProductList-item').setStyle('clear', 'none');
      this.get('host').all('.ProductList-item:nth-child(' + itemsPerRow + 'n+1)').setStyle('clear', 'left');
      this.get('host').one('.ProductList-grid').setAttribute('data-items-per-row', itemsPerRow);

    },

    /**
     * Show users a preview of "Item Hover Behavior: Show Alternate Image".
     * When they set this tweak, the second product image of the first item will
     * fade in and out.
     *
     * @method _previewTweakChangeItemHoverBehavior
     */
    _previewTweakChangeItemHoverBehavior: function () {
      var firstAlternateImage = this.get('host').one('.ProductList-image--alt');
      var preview = function () {
        firstAlternateImage.setStyle('opacity', 1);
        Y.later(1000, this, function () {
          firstAlternateImage.setStyle('opacity', '');
        });
      };

      if (firstAlternateImage.hasClass('loaded')) {
        preview();
      } else {
        firstAlternateImage.once('load', function () {
          preview();
        });
      }
    } },

  {

    ATTRS: {

      host: {
        value: null,
        validator: function (val) {
          var node = Y.one(val);
          return Y.instanceOf(node, Y.Node);
        },
        writeOnce: true },


      /**
       * If users have it set to show the second product image when an item is
       * hovered, there will be two images per product. This behavior is only
       * applicable if users have item details set to appear below the item images.
       *
       * @type {Object}
       */
      images: {
        getter: function () {

          var isHoverBehaviorShowAltImage =
          Y.one('.tweak-product-list-item-hover-behavior-show-alternate-image') &&
          Y.one('.tweak-product-list-meta-position-under');

          if (isHoverBehaviorShowAltImage && !Y.UA.mobile) {
            return this.get('host').all('.ProductList-image[data-src]');
          }
          return this.get('host').all('.ProductList-image--primary[data-src]');

        } } } });






  var productListInstances = [];

  Y.config.win.Squarespace.onInitialize(Y, function () {
    Y.all('.ProductList.products-collection-v2').each(function (listEl) {
      // Run inline script logic
      var tweakItemsPerRow = Y.config.win.Static.SQUARESPACE_CONTEXT.tweakJSON['tweak-product-list-items-per-row'];
      listEl.one('.ProductList-grid').getDOMNode().dataset.itemsPerRow = tweakItemsPerRow;
      productListInstances.push(
      new Y.Squarespace.SystemCollections.Products.ListV2({
        host: listEl }));


    });
  });

  Y.config.win.Squarespace.onDestroy(Y, function () {
    productListInstances.forEach(function (instance) {
      instance.destroy();
    });
    productListInstances.length = 0;
  });


}, '1.0', {
  requires: [
  'base',
  'node',
  'squarespace-beforeunload',
  'squarespace-image-loader',
  'squarespace-image-zoom',
  'squarespace-public-api'] });
export function getWidthForHeight(originalWidth, originalHeight, height) {
  return (originalWidth / originalHeight) * height;
}

export function getHeightForWidth(originalWidth, originalHeight, width) {
  return (originalHeight / originalWidth) * width;
}

export function getDimensionsFromNode(node) {
  const dimensions = node.getAttribute('data-image-dimensions');

  if (typeof dimensions === 'string') {
    const [width, height] = dimensions.split('x').map(x => Number(x));
    return { width, height };
  }

  return { width: null, height: null };
}

export function getSquarespaceSizeForWidth(width) {
  if (window.devicePixelRatio) {
    // Adjust for retina screens.
    width *= window.devicePixelRatio;
  }

  if (width > 1500) {
    return '2500w';
  } else if (width > 1000) {
    return '1500w';
  } else if (width > 750) {
    return '1000w';
  } else if (width > 500) {
    return '750w';
  } else if (width > 300) {
    return '500w';
  } else if (width > 100) {
    return '300w';
  }
  return '100w';
}

export function getIconUrl(name, size, style) {
  return `/universal/images-v6/icons/icon-${name}-${size}-${style}.png`;
}

import * as rendering from 'shared/utils/rendering';
// eslint-disable-next-line import/no-unresolved
import '../styles-compressed/legacy/image-zoom.css';
/**
 * The module that contains the ImageZoom class. This
 * is primarily designed for product collections to
 * allow shoppers to get a closer look at the products
 * they're viewing.
 *
 * @exports squarespace-image-zoom
 */
YUI.add('squarespace-image-zoom', function(Y) {

  /**
   * @namespace Squarespace
   */
  Y.namespace('Squarespace');

  /**
   * The class that creates the image zoom behavior.
   *
   * @class ImageZoom
   * @memberof Squarespace
   * @example
   * new Y.Squarespace.ImageZoom({
   *   host: Y.one('.some-node')
   * });
   */
  var ImageZoom = Y.Squarespace.ImageZoom = Y.Base.create('image-zoom', Y.Base, [], {

    initializer: function() {

      if (!this._shouldInitialize()) {
        return;
      }

      // Make sure the user passed the required arguments.
      if (!this.get('host')) {
        if (__DEV__) {
          console.warn('[squarespace-image-zoom]: Missing host attribute.');
        }
        return;
      }

      this._initializeZoom();
    },

    destructor: function() {

      if (!this._shouldInitialize()) {
        return;
      }

      var zoomedNode = this.get('zoomedNode');
      if (zoomedNode) {
        zoomedNode.remove(true);
      }

      this.get('host')
        .removeClass(ImageZoom.CSS_PREFIX)
        .removeClass(ImageZoom.CSS_PREFIX + '--behavior-' + this.get('behavior'));

      this.get('dropzone')
        .setStyle('position', '')
        .removeClass(ImageZoom.CSS_PREFIX + '-dropzone');

      if (this._zoomTriggerEvent) {
        this._zoomTriggerEvent.detach();
      }
      if (this._mouseMoveEvent) {
        this._mouseMoveEvent.detach();
      }
      if (this._mouseOutEvent) {
        this._mouseOutEvent.detach();
      }
      if (this._resizeEvent) {
        this._resizeEvent.detach();
      }

      this._zoomTriggerEvent = null;
      this._mouseMoveEvent = null;
      this._mouseOutEvent = null;
      this._resizeEvent = null;
    },

    _bindUI: function() {
      var host = this.get('host');

      this._zoomTriggerEvent = host.on(this.get('behavior'), this._toggleZoom, this);
      this._mouseMoveEvent = host.on('mousemove', this._trackMovement, this);
      this._mouseOutEvent = host.on('mouseout', this._zoomOut, this);
      this._resizeEvent = Y.one(window).on('resize', this._refresh, this);
    },

    /**
     * Image zoom heavily relies on mouse events. For
     * now we're just not going to invoke it on mobile
     * devices, opting instead to allow the user to use
     * the browser's built-in pinch-to-zoom. Also, this
     * module uses 2D CSS transforms. We'll stop the
     * script if either of these conditions aren't met.
     *
     * @method _shouldInitialize
     * @return {boolean} Whether image zoom should initialize
     */
    _shouldInitialize: function() {
      return !Y.UA.mobile && window.Modernizr.csstransforms;
    },

    /**
     * Setup markup with relevant CSS classes, then append the markup for the
     * duplicated zoomed image.
     *
     * @method _initializeZoom
     */
    _initializeZoom: function() {
      var host = this.get('host');
      var image = host.one('img');
      var dropzone = this.get('dropzone');

      host.addClass(ImageZoom.CSS_PREFIX);
      host.addClass(ImageZoom.CSS_PREFIX + '--behavior-' + this.get('behavior'));
      dropzone.addClass(ImageZoom.CSS_PREFIX + '-dropzone');

      // The dropzone needs any position other than static.
      if (dropzone.getStyle('position') === 'static') {
        dropzone.setStyle('position', 'relative');
      }

      // Don't set up the zoomed image until the host image is loaded.
      if (!image.getAttribute('src')) {
        image.once('load', function() {
          this._appendZoomedNode();
          this._bindUI();
        }, this);
      } else {
        this._appendZoomedNode();
        this._bindUI();
      }
    },

    /**
     * Create the template for the duplicated zoomed image and append it to the
     * dropzone.
     *
     * @method _appendZoomedNode
     */
    _appendZoomedNode: function() {
      var image = this.get('host').one('img');

      var src = image.getAttribute('data-src');
      if (!src) {
        if (__DEV__) {
          console.warn('[squarespace-image-zoom]: Host image did not load properly; missing data-src.');
        }
        return null;
      }

      var dimensions = image.getAttribute('data-image-dimensions');
      if (!dimensions) {
        dimensions = (image.get('clientWidth')) + 'x' + (image.get('clientHeight'));
      }

      var focalPoint = image.getAttribute('data-image-focal-point');
      if (!focalPoint) {
        focalPoint = '0.5,0.5';
      }

      var zoomedNode = Y.Node.create('<div class="' + ImageZoom.CSS_PREFIX + '-duplicate">' +
        '<img src="' + src + '?format=' + this._getSquarespaceSizeForWidth() + '" ' +
        'data-image-dimensions="' + dimensions + '" ' +
        'data-image-focal-point="' + focalPoint + '">' +
      '</div>');

      zoomedNode.setStyle('transform', 'scale(' + this.get('zoom') + ')');

      zoomedNode.one('img').plug(Y.Squarespace.Loader2, {
        load: true,
        mode: 'fill'
      });

      this.set('zoomedNode', zoomedNode);
      this.get('dropzone').append(zoomedNode);
    },

    _refresh: function() {
      var src = this.get('host').one('img').getAttribute('data-src');
      var zoomedImage = this.get('zoomedNode').one('img');
      zoomedImage.setAttribute('src', src + '?format=' + this._getSquarespaceSizeForWidth());
      zoomedImage.fire('refresh');
    },

    /**
     * Dispatches a call to either zoomIn or zoomOut
     * depending on the zoom state.
     *
     * @method _toggleZoom
     * @param {Object} e
     */
    _toggleZoom: function(e) {
      if (this.get('_isZoomedIn')) {
        this._zoomOut();
      } else {
        this._zoomIn(e);
      }

      // Stop the propagation after we invoke the
      // above methods because we sometimes need
      // events to propagate to their parent.
      e.stopPropagation();
    },

    /**
     * Update the zoomed state and add a class that will
     * initiate the visual effects.
     *
     * @method _zoomIn
     * @param {Object} e
     */
    _zoomIn: function(e) {
      this.get('host').addClass('is-zoomed');
      this.set('_isZoomedIn', true);
      this._trackMovement(e);
    },

    /**
     * Update the zoomed state and add a class that will
     * initiate the visual effects.
     *
     * @method _zoomOut
     */
    _zoomOut: function() {
      this.get('host').removeClass('is-zoomed');
      this.set('_isZoomedIn', false);
    },

    /**
     * Watches the mousemove on the host element. These
     * movements get mapped into a transformOrigin on the
     * zoomed image.
     *
     * @method _trackMovement
     * @param {Object} e
     */
    _trackMovement: function(e) {
      if (!this.get('_isZoomedIn')) {
        return;
      }

      var xPos = Math.max((e.pageX - this.get('host').getX()) / this.get('host').get('clientWidth') * 100, 0);
      var yPos = Math.max((e.pageY - this.get('host').getY()) / this.get('host').get('clientHeight') * 100, 0);

      this.get('zoomedNode').setStyle('transformOrigin', xPos + '% ' + yPos + '%');
    },

    /**
     * Get the official Squarespace size format for the host image width.
     *
     * @method _getImageSizeString
     * @return {String}
     */
    _getSquarespaceSizeForWidth: function() {
      var imageWidth = this.get('host').one('img').get('clientWidth');
      return rendering.getSquarespaceSizeForWidth(imageWidth * this.get('zoom'));
    }

  }, {
    CSS_PREFIX: 'sqs-image-zoom',
    ATTRS: {

      /**
       * This is the wrapper around the image that we want to
       * zoom in on. It has to be a Y.Node or a vanilla Node
       * and it has to have one single image as a child.
       *
       * @attr {Y.Node} host
       * @default null
       */
      host: {
        value: null,
        validator: function(val) {
          var node = Y.one(val);

          return Y.instanceOf(node, Y.Node) &&
            node.one('img') &&
            node.all('img').size() < 2;
        },
        writeOnce: true
      },

      /**
       * This is where the zoomed in image will be visible.
       * It defaults to the host node.
       *
       * @attr {Y.Node} dropzone
       * @default null
       */
      dropzone: {
        valueFn: function() {
          return this.get('host');
        },
        validator: function(val) {
          return Y.instanceOf(Y.one(val), Y.Node);
        },
        writeOnce: true
      },

      /**
       * Defines the event that triggers the image
       * zoom. Possible options are hover and click.
       *
       * @attr {String} behavior
       * @default "hover"
       */
      behavior: {
        value: 'hover',
        validator: function(val) {
          if (val !== 'hover' && val !== 'click') {
            if (__DEV__) {
              console.warn('[squarespace-image-zoom]: Not a valid behavior, defaulting to hover.');
            }
            return false;
          }

          return true;
        },
        writeOnce: true
      },

      /**
       * How far should we zoom in on the image? We
       * set a minimum of 1 (what would be the point?)
       * and a max of 5.
       *
       * @attr {Number} zoom
       * @default 1.5
       */
      zoom: {
        value: 1.5,
        validator: function(val) {
          if ((typeof val !== 'number' || (val <= 1 || val > 5)) && __DEV__) {
            console.warn('[squarespace-image-zoom]: Not a valid zoom value, defaulting to 1.5.');
          }

          return true;
        },
        writeOnce: true
      },

      /**
       * Manages the state of the image zoom.
       *
       * @attr {Boolean} _isZoomedIn
       * @default false
       */
      _isZoomedIn: {
        value: false
      }
    }
  });

}, '1.0', {
  requires: [
    'base',
    'event',
    'node',
    'squarespace-image-loader',
    'yui-base'
  ]
});

